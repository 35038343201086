<template>
  <div class="app">
    <div class="background" :style="backgroundStyle">
      <div class="button-container"  v-if="configLoaded">
        <div class="button" v-for="(button, index) in buttons" :key="index">
          <img :src="button.src" :alt="button.alt" @click="openUrl(button.url)" v-if="button.isShow"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from './assets/bg.png';
import androidButton from './assets/android.png';
import ios1Button from './assets/ios.png';

export default {
  data() {
    return {
      buttons: [
        { src: androidButton, alt: 'Android下载', url: '' ,isShow: false},
        { src: ios1Button, alt: 'iOS下载', url: '' ,isShow: false},
      ],
      configLoaded: true
    };
  },
  computed: {
    backgroundStyle() {
      return {
        backgroundImage: `url(${bgImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat'
      };
    },
  },
  methods: {
    fetchConfig() {
      const timestamp = new Date().getTime(); // 确保获取最新的 config.json
      fetch(`/config.json?${timestamp}`)
        .then(response => response.json())
        .then(configData => {
          const appUrl = configData.app;
          const androidUrl = configData.android;
          // this.buttons[0].url = this.addRandomPrefix(androidUrl);
          this.buttons[0].url = androidUrl;
          this.buttons[0].isShow = true;
          // 请求 app 对应的 URL
          return fetch(appUrl);
        })
        .then(appResponse => appResponse.json())
        .then(appData => {
          // 解析 link_jump 并赋值给 buttons
          const linkJump = appData.link_jump;
         
          this.buttons[1].url = linkJump;
          this.buttons[1].isShow = true;
          this.configLoaded = true;
        })
        .catch(error => console.error('Error fetching config or app data:', error));
    },
    addRandomPrefix(url) {
      const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
      let prefix = '';
      for (let i = 0; i < 5; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        prefix += characters[randomIndex];
      }
      return `http://${prefix}.${url}`;
    },
    openUrl(url) {
      if (url) {
        window.location.href = url;
        // window.open(url, '_blank');
      } else {
        alert('请刷新页面后下载');
        this.fetchConfig();
      }
    },
  },
  mounted() {
    this.fetchConfig();
    document.body.style.margin = '0';
    document.documentElement.style.margin = '0';
  },
};
</script>

<style scoped>
* {
  margin: 0;   /* 移除默认 margin */
  padding: 0;  /* 移除默认 padding */
  box-sizing: border-box; /* 确保 padding 和 border 包含在元素的高度和宽度内 */
}
html, body {
  width: 100%;
  height: 100%;
  overflow: hidden; /* 防止页面滚动 */
}
.app {
  width: 100%;
  height: 100vh; /* 占满整个页面的可视高度 */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}
.background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-image: url('./assets/bg.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.button-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  bottom: 100px;
}
.button img {
  width: 223px; /* 按钮图片的宽度，保持比例缩放 */
  cursor: pointer;
}
@media screen and (min-width: 768px) {
  .app {
    max-width: 480px;
    height: 100vh; /* 同样占满整个页面的可视高度 */
    border: 1px solid #ccc;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
